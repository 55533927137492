import React, { useEffect, useRef, useState, useCallback } from 'react';
import { api } from '@buzzfeed/consent';
import { useNewsFooterTracking } from '../../hooks/analytics/useNewsFooterTracking';
import { brandItems } from '../../constants/news-footer';
import { BuzzfeedNewsLogo } from '../Svgs/BuzzfeedNewsLogo';
import { BuzzfeedLogo } from '../Svgs/BuzzfeedLogo';
import styles from './newspagefooter.module.scss';

const NewsPageFooter = ({ buzz }) => {
  const newsFooterRef = useRef(null);
  const trackFn = useNewsFooterTracking(buzz);
  const [showCcpa, setShowCcpa] = useState(false);

  const createTrackingData = ({ item_name, subunit_name, position_in_unit, target_content_url = '' }, position_in_subunit) => {
    let trackingData = {
      item_name,
      subunit_name,
      position_in_unit,
      position_in_subunit
    };
    if (target_content_url) {
      trackingData.target_content_url = target_content_url;
    } else {
      trackingData.target_content_type = 'feed';
    }
    return trackingData;
  };

  useEffect(() => {
    if (!newsFooterRef && !newsFooterRef.current && !trackFn) {
      return;
    }

    newsFooterRef.current.querySelector('.js-list-brands').querySelectorAll('a').forEach((link, index) => {
      let trackingData;
      if (index === 1) {
        // Buzzfeed brand
        trackingData = createTrackingData(brandItems[index], index);
      } else {
        // BuzzfeedNews logo
        trackingData = createTrackingData(brandItems[index], index);
        trackFn.impressionUnit({
          element: link,
          trackingData
        });
      }

      trackFn.clickUnit({
        element: link,
        trackingData
      });
    });
  }, [newsFooterRef, trackFn]);

  const onCcpaClick = useCallback(() => {
    trackFn.contentAction({
      item_name: 'ccpa_button',
      item_type: 'button',
      unit_name: 'overlay',
      unit_type: 'nav',
      action_type: 'show',
      action_value: 'ccpa_modal'
    });
    window.__uspapi('displayUspUi');
  }, []);

  useEffect(() => {
    api.getUSPData()
      .then(({ uspData }) => {
        //checking if user is in US before loading ccpa
        //californians have the option to opt out from anywhere in the us
        if (uspData.uspString !== '1---') {
          setShowCcpa(true);
        }
      });
  }, []);

  return (
    <div ref={newsFooterRef} className={`${styles.pageFooter} xs-px2 xs-py3 sm-p5 xs-mt3 embed-page-footer`}>
      <footer className={styles.container} id="news-footer" tabIndex={-1}>
        <div className={styles.footerBottom}>
          <div className={styles.ccpa}>
            {showCcpa && (
              <>
                <p>US residents can opt out of &#34;sales&#34; of personal data.</p>
                <button className={styles.button} onClick={onCcpaClick}>Do not sell or share my personal information</button>
              </>
            )}
          </div>
          <ul className={`${styles.list} ${styles.listBrand} js-list-brands`}>
            <li className={styles.listItem}>
              <a className={styles.listLink} href="https://buzzfeednews.com"><BuzzfeedNewsLogo className={styles.buzzfeedNewsLogo}/></a>
            </li>
            <li className={styles.listItem}>
              <a className={styles.listLink} href="https://www.buzzfeed.com">a <BuzzfeedLogo className={styles.buzzfeedLogo}/> brand.</a>
            </li>
          </ul>
        </div>
        <div>
          <ul className={styles.footerList}>
            <li className={styles.footerListItem}>© 2025 BuzzFeed, Inc</li>
            <li className={styles.footerListItem}><a href="https://www.buzzfeed.com/press" className={styles.footerLink}>Press</a></li>
            <li className={styles.footerListItem}><a href="https://www.buzzfeed.com/rss" className={styles.footerLink}>RSS</a></li>
            <li className={styles.footerListItem}><a href="https://www.buzzfeed.com/about/privacy" className={styles.footerLink}>Privacy</a></li>
            <li className={styles.footerListItem}><a href="https://www.buzzfeed.com/consent-preferences" className={styles.footerLink}>Consent Preferences</a></li>
            <li className={styles.footerListItem}><a href="https://www.buzzfeed.com/about/useragreement" className={styles.footerLink}>User Terms</a></li>
            <li className={styles.footerListItem}><a href="https://www.buzzfeed.com/about/accessibility" className={styles.footerLink}>Accessibility Statement</a></li>
            <li className={styles.footerListItem}><a href="https://www.buzzfeed.com/about/privacy#adchoices" className={styles.footerLink}>Ad Choices</a></li>
            <li className={styles.footerListItem}><a href="https://www.buzzfeed.com/help" className={styles.footerLink}>Help</a></li>
            <li className={styles.footerListItem}><a href="https://www.buzzfeed.com/about/contact" className={styles.footerLink}>Contact</a></li>
            <li className={styles.footerListItem}><a href="https://www.buzzfeed.com/archive" className={styles.footerLink}>Sitemap</a></li>
          </ul>
        </div>
      </footer>
    </div>
  );
};

export default NewsPageFooter;

import { useEffect } from 'react';
import { api } from '@buzzfeed/consent';

/**
 * Serves two purposes:
 * 1) explicitly initializes the CMP (which is necessary since a CMP stub is defined in <head>,
 * otherwise calls to ` __tcfapi` made by 3rd parties that rely on consent will be pending forever)
 * 2) manages whether Quantcast Choice or a dummy fallback will be used as a CMP
 * based on a feature flag state (feature flag may be turned off temporarily in case of emergency,
 * in which case the fallback will be used until it's back on)
 * 09/20/2023: removed the feature flag for OneTrust (the new CMP)
 */
export default function ConsentManagement({ isBFN = false }) {
  useEffect(() => {
    api.configure({ useFallback: false });
    api.init({ isBFN });
  }, []);

  return null;
}

import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import AdsProvider from '@buzzfeed/adlib/dist/module/bindings/react/components/AdsContext';
import DestinationContext from '../../contexts/destination';
import { getPageContext } from '../../utils/ads/context';
import useLegacyTracking from '../../hooks/analytics/useLegacyTracking';
import useABeagleAsync from '../../hooks/useABeagleAsync';

async function getPageTargeting({ buzz, badges, abeagle }) {
  const pageTargeting = {
    bfmono: 'bpage_new',
    bid: buzz.id,
    user: buzz.username,
    nbs:
      !buzz.flags.brand_safe || buzz.flags.nsfw || buzz.flags.sensitive
        ? '1'
        : '0',
    nsfw: buzz.flags.nsfw || !buzz.isModerated ? '1' : '0',
    sensitive: buzz.flags.sensitive ? '1' : '0',
    trending: buzz.tags.includes('--trending') ? '1' : '0',
    tag: buzz.tags
      .map((tag) => tag.match(/^--ad-(\w+)$/)) // https://regex101.com/r/LkRQqb/1
      .filter((match) => match)
      .map((match) => match[1]),
    badges: badges.map((badge) => badge.badge_type),
    brain_tags: [], // @todo
    pagetype: buzz.isCommentsPage ? 'c' : 'b',
  };

  const experiments = [
    'commerce_ad_affiliate',
    'commerce_ad_recirc',
    'SITE-7528-TPAU_with_images',
    'ads_mweb_quiz_promo-inline1',
    'ads_shopping_density_test',
    'ads_delay_fetch_and_destroy',
    'RT-946-shopping-recirc-bfn',
    'ads_delay_fetch',
    'RT-947-remove-semisticky-bfn',
    'RT-945-tb-size-bfn-desktop',
    'ads_retail_media_network_exposure',
    'RT-1378-us-quiz-top-sticky-desktop',
    'ads-quiz-top-player-mobile',
  ];

  const experimentVariants = await Promise.all(
    experiments.map((experiment) =>
      abeagle.getExperimentVariant(experiment, {
        rejectErrors: false,
        defaultVariantIfUnbucketed: null,
      })
    )
  );

  pageTargeting.abtest = experiments.map(
    (experiment, idx) => `${experiment}|${experimentVariants[idx]}`
  );

  return pageTargeting;
}

export default function Ads(props) {
  const buzz = props.buzz;
  const badges = props.badges;
  const legacyTracking = useLegacyTracking(buzz);
  const abeagle = useABeagleAsync();
  const { base_url } = useContext(DestinationContext);

  const getPageContextBound = useCallback(() => {
    return getPageContext({
      base_url,
      buzz,
      abeagle,
      tracking: legacyTracking,
    });
  }, [buzz.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const getPageTargetingBound = useCallback(
    () => getPageTargeting({ buzz, badges, abeagle }),
    [buzz.id] // eslint-disable-line react-hooks/exhaustive-deps
  );
  // const getSlotTargetingBound = useCallback(({ wid, adPos }) => {}, []);

  return (
    <AdsProvider
      pageId={buzz.id}
      pageLanguage={buzz.language}
      adsEnabled={!buzz.disableAds}
      getPageContext={getPageContextBound}
      getPageTargeting={getPageTargetingBound}
      // getSlotTargeting={getSlotTargetingBound}
    >
      {props.children}
    </AdsProvider>
  );
}

Ads.propTypes = {
  buzz: PropTypes.object.isRequired,
  badges: PropTypes.array.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

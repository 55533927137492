export const brandItems = [
  {
    text: 'BuzzFeed News Home',
    link: 'https://buzzfeednews.com',
    item_name: 'buzzfeednews_logo',
    subunit_name: 'brand_info',
    position_in_unit: 3,
  },
  {
    text: 'BuzzFeed',
    link: 'https://www.buzzfeed.com',
    item_name: 'buzzfeed_logo',
    subunit_name: 'brand_info',
    position_in_unit: 3,
    target_content_url: 'https://www.buzzfeed.com',
  }
];

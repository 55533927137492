import AdUnit from '../../AdUnit';
import styles from './awareness-ad.module.scss';
import { useBreakpoint } from '@buzzfeed/react-components';
import { useEffect, useState } from 'react';

function AwarenessAdUnit({ buzz }) {
  const [mounted, setMounted] = useState(false);
  const isLg = useBreakpoint('(min-width: 64rem)');
  // Since the server render will always be on desktop height,
  // need to wait until mounted for reliable actual breakpoint
  const displayAd = mounted && !(buzz.isNews && isLg);
  //This supports some feature pages that use news styling, but have destination == buzzfeed
  //buzz.isNews checks the metavertical section vs. the destination
  const destination_style = buzz.isNews ? 'buzzfeed_news' : buzz.destination_name;

  useEffect(() => {
    setMounted(true)
  }, []);

  if (buzz.disableAds) {
    return null;
  }

  return (
    <>
      {/* While waiting for mount, use placeholder to provide some min-height to avoid CLS shift
        for buzzfeed above the nav */}
      {
        !displayAd &&
        <div className={`${styles.placeholderAd} ${styles[destination_style]}`}></div>
      }
      {displayAd &&
        <AdUnit
          type="awareness"
          pixiedust={{
            unit_type: 'buzz_head',
            unit_name: buzz.id,
            position_in_unit: 0,
        }}
        />
      }
    </>
  )
};

export default AwarenessAdUnit;
